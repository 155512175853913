<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto md:overflow-hidden sm:-mx-6 lg:-mx-8">
      <div
        class="inline-block min-w-full py-2 overflow-hidden align-middle sm:px-6 lg:px-8"
      >
        <div
          class="flex flex-col items-center justify-center h-48"
          v-if="items.length === 0"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-12 h-12 text-base-300"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="my-2 font-normal text-gray-700">No hay categorias</div>
        </div>
        <div
          v-else
          class="overflow-hidden shadow bcategory-b bcategory-gray-200 sm:rounded-lg"
        >
          <div
            class="flex justify-between px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden bg-gray-50"
          >
            <div>Nombre</div>
            <div>Acciones</div>
          </div>
          <div class="bg-white">
            <draggable
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              v-model="categories"
              @change="change"
              tag="tbody"
              class="flex flex-1 flex-grow bg-white divide-y divide-gray-300 sm:flex-none print:flex-none"
            >
              <transition-group
                type="transition"
                tag="div"
                class="w-full"
                :name="!drag ? 'flip-list' : null"
              >
                <div
                  v-for="category in categories"
                  :key="category._id"
                  class="flex items-center justify-between flex-1 py-2 bg-white border-b sm:flex-none print:flex-none"
                >
                  <div
                    class="flex items-center overflow-hidden whitespace-no-wrap"
                  >
                    <div class="w-2 px-6 text-xs">{{ category.sort + 1 }}</div>
                    <div>
                      <div class="text-sm">{{ category.label }}</div>
                      <div class="text-xs italic text-gray-700">
                        {{ category.key }}
                      </div>
                    </div>
                  </div>
                  <div class="px-6 md:w-32">
                    <div class="relative flex justify-end print:hidden">
                      <button
                        v-tooltip="'Mover'"
                        class="p-1 py-1 text-xs text-left hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                      >
                        <svg
                          class="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                          />
                        </svg>
                      </button>
                      <a
                        v-if="category.label"
                        v-tooltip="'Ver categoria en la tienda'"
                        target="_blank"
                        :href="
                          'https://tienda.orsai.org/' +
                          category.label.toLowerCase()
                        "
                        class="p-1 py-1 text-xs text-left hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                      >
                        <svg
                          class="w-5 h-5 mr-1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                      </a>
                      <router-link
                        v-tooltip="'Editar categoria'"
                        class="p-1 py-1 text-xs text-left hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                        :to="{
                          name: 'categories-id',
                          params: {
                            id: category._id,
                          },
                        }"
                      >
                        <svg
                          class="w-5 h-5 mr-1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          /></svg
                      ></router-link>
                      <button
                        v-tooltip="'Eliminar categoria'"
                        class="flex items-center p-1 py-1 text-xs text-left border-t border-gray-200 cursor-pointer hover:bg-red-500 hover:text-white rounded"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div> </transition-group
            ></draggable>
          </div>
          <div
            v-if="totalResults > limit"
            @click="nextPage()"
            class="items-center p-3 text-sm font-medium text-center text-indigo-300 cursor-pointer glex hover:text-indigo-500"
          >
            Cargar 20 más de {{ totalResults - limit }} resultados.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions } from "vuex";
export default {
  components: {
    draggable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    eventPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showImage: null,
      upload: null,
      limit: 20,
      busy: false,
      categories: [],
      drag: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        ghostClass: "ghost",
      };
    },
  },
  mounted() {
    this.categories = this.items;
  },
  methods: {
    ...mapActions("categories", {
      patch: "patch",
    }),
    change() {
      // const category = value.moved.element;
      // this.categories[value.moved.newIndex].sort = value.moved.newIndex;
      // this.patch([category._id, { sort: value.moved.newIndex }]);
      this.categories.forEach((category, index) => {
        console.log(category.name, index);
        this.categories[index].sort = index;
        this.patch([this.categories[index]._id, { sort: index }]);
      });
    },
    nextPage() {
      this.limit += 20;
      this.$emit("next-page", this.limit);
    },
  },
};
</script>

<style lang="css" scoped>
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  bcategory-bottom: 0;
}

th:not(:last-child) {
  bcategory-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
