<template>
  <div>
    <w-top-bar>Categorias</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div
        v-if="!isFindCategoriesPending"
        class="flex items-center justify-end h-12 px-1"
      >
        <router-link
          :to="{ name: 'categories-id', params: { id: 'new' } }"
          class="text-sm text-blue-500 cursor-pointer hover:text-blue-600"
          >Nueva Categoria</router-link
        >
      </div>
      <transition
        leave-active-class="transition-all duration-300 ease-in-out"
        enter-active-class="transition-all duration-200 ease-in-out"
        enter-class="opacity-0 "
        enter-to-class="opacity-100 "
        leave-to-class="opacity-100 "
        leave-class="opacity-0 "
      >
        {{ categories }}
        <w-categories v-if="!isFindCategoriesPending" :items="categories" />
        <w-loading v-else />
      </transition>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex"; // Step 1
import WLoading from "../../components/WLoading.vue";

export default {
  components: { WLoading },
  name: "categoriesList",
  data() {
    return {};
  },
  mixins: [makeFindMixin({ service: "categories" })], // Step 2
  computed: {
    categoriesParams() {
      let query = {};
      return {
        query: {
          ...query,
          $sort: {
            sort: 1,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
